import { createSlice } from '@reduxjs/toolkit';

const searchHotelSlice = createSlice({
    name: 'searchHotel',
    initialState: {
        destination: "",
        date: { checkIn: null, checkOut: null, nights: 0 },
        roomAndGuest: { rooms: 1, adults: 1, children: 0 }
    },
    reducers: {
        setDestination: (state, action) => {
            state.destination = action.payload;
        },
        setDate: (state, action) => {
            state.date = action.payload;
        },
        setRoomAndGuest: (state, action) => {
            state.roomAndGuest = action.payload;
        }
    },
});

export const { setDestination, setDate, setRoomAndGuest } = searchHotelSlice.actions;
export default searchHotelSlice.reducer;
