import { Col, Row, } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  InstagramOutlined,
  FacebookOutlined,
  XOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";

const AppFooter = () => {

  return (
    <div className="container">
      <Row>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <div className="footer-widget">
            <h3 className="footer-title">Reservations</h3>
            <div className="footer-sub-title">Book Online</div>
            <ul>
              <li><span>Reservation line:</span><Link>+1-855-946-6600</Link></li>
              <li><Link>Main Line</Link></li>
              <li><Link>Modify a Reservation</Link></li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <div className="footer-widget">
            <h3 className="footer-title">About us</h3>
            <div className="footer-sub-title">Mobile App</div>
            <ul>
              <li><Link>Careers</Link></li>
              <li><Link>FAQ's</Link></li>
              <li><Link>Press</Link></li>
              <li><Link>Diversity, Equality & Inclusion</Link></li>
              <li><Link>Management & Development</Link></li>
              <li><Link>Sustainability</Link></li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <div className="footer-widget">
            <h3 className="footer-title">The Legal Bits</h3>
            <div className="footer-sub-title">Privacy Policy</div>
            <ul>
              <li><Link>Term & Conditions</Link></li>
              <li><Link>Cookie Policy</Link></li>
            </ul>
          </div>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6} xl={6}>
          <div className="footer-widget">
            <h3 className="footer-title">Follow us</h3>
            <ul className="sodial-icon">
              <li><InstagramOutlined /></li>
              <li><XOutlined /></li>
              <li><FacebookOutlined /></li>
              <li><YoutubeOutlined /></li>
            </ul>
            <p>@2024 Hotelapps.cloud. All Rights Reserved</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default AppFooter;