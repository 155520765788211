import { Button } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  applyPromoCode,
  clearPromoCode,
  removeItemFromCart,
} from "../redux/cartSlice";
import { CloseCircleFilled } from "@ant-design/icons";

const Cart = () => {
  const getCartData = useSelector((state) => state.cart);
  const getHotels = useSelector((state) => state.hotel.hotels);
  console.log(getHotels);
  console.log(getCartData);

  const dispatch = useDispatch();

  const removeItem = (id) => {
    dispatch(removeItemFromCart(id));
  };

  // Promo code

  const [promoCode, setPromoCode] = useState("");

  const handleApplyPromo = () => {
    dispatch(applyPromoCode(promoCode));
  };

  const removePromocode = () => {
    dispatch(clearPromoCode());
  };

  return (
    <div className="hotel-cart">
      <div className="cart-top">
        <h3>Your Cart</h3>
      </div>
      <div className="cart-middle">
        {/*  */}
        {getCartData.selectedRoom && (
          <div className="cart-itme">
            <span className="room-price">
              ${getCartData.selectedRoom.totalPrice}
            </span>
            <div className="cart-room-name">
              {getCartData.selectedRoom.name}
            </div>
            <div className="hotel-name">
              {getHotels.find(
                (hotel) => hotel.id === getCartData.selectedRoom.hotelId
              )?.hotelname || ""}
            </div>
            <div className="room-booking-date">
              <b>${getCartData.selectedRoom.price} / Night</b>
            </div>
            <div className="room-booking-date">
              {getCartData.selectedRoom.checkIn && (
                <>
                  {getCartData.selectedRoom.checkIn} -{" "}
                  {getCartData.selectedRoom.checkOut}
                </>
              )}
              {getCartData.selectedRoom.date && (
                <>
                  {getCartData.selectedRoom.date} -{" "}
                  {getCartData.selectedRoom.time}
                </>
              )}
            </div>
            {getCartData.selectedRoom.guests && (
              <div className="hotel-stay">
                {getCartData.selectedRoom.guests} Guests |{" "}
                {getCartData.selectedRoom.nights} Nights
              </div>
            )}
            {/* <Button
              type="light"
              onClick={() => removeItem(getCartData.selectedRoom.id)}
              className="cart-remove"
            >
              X Remove
            </Button> */}
          </div>
        )}
        {/*  */}
        {getCartData?.items?.map((item, index) => (
          <div className="cart-itme" key={index}>
            <span className="room-price">
              {item.price ? `$${item.price}` : ""}
            </span>
            <div className="cart-room-name">{item.name}</div>
            
            <div className="hotel-name">
              {getHotels.find((hotel) => hotel.id === item.hotelId)
                ?.hotelname || ""}

              {item.occasion && <>{item.occasion} </>}
              {item.specials && (
                <>
                  <br></br> {item.specials}
                </>
              )}
              {item.Experience && (
                <>
                  {" "}
                  <br></br> {item.Experience}
                </>
              )}
            </div>
            <div className="room-booking-date">
              {item.checkIn && (
                <>
                  {item.checkIn} - {item.checkOut}
                </>
              )}
              {item.date && (
                <>
                  {item.date} - {item.time}
                </>
              )}
            </div>
            {item.guests && (
              <div className="hotel-stay">
                {item.guests} Guests | {item.nights} Nights
              </div>
            )}
            <Button
              type="light"
              onClick={() => removeItem(item.id)}
              className="cart-remove"
            >
              X Remove
            </Button>
          </div>
        ))}
      </div>
      <div className="cart-middle">
        <ul className="cart-total-price">
          <li>
            <span className="name">Subtotal</span>
            <span className="item-price">${getCartData.subTotal}</span>
          </li>
          <li>
            <span className="name">Taxes</span>
            <span className="item-price">${getCartData.taxes}</span>
          </li>
          <li>
            <span className="name">Discount</span>
            <span className="item-price">${getCartData.discount}</span>
          </li>
          {getCartData.appliedPromoCode && (
            <li>
              <span className="name">
                promocode discount (
                {getCartData.appliedPromoCode.type === "fixed" && "$"}
                {getCartData.appliedPromoCode.value}
                {getCartData.appliedPromoCode.type === "percentage" && "%"})
                <span style={{ cursor: "pointer" }} onClick={removePromocode}>
                  {" "}
                  <CloseCircleFilled />{" "}
                </span>
              </span>
              <span className="item-price">${getCartData.promoAmount}</span>
            </li>
          )}
          <li>
            <span className="name">Total Due </span>
            <span className="item-price">${getCartData.totalAmount}</span>
          </li>
        </ul>
      </div>
      <div className="cart-middle">
        <div className="promo-code">
          <label>Have a gift certificate or promo code?</label>
          <Input
            placeholder="Enter Your Promo Code"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
          />
          <Button className="apply-btn" onClick={handleApplyPromo}>
            Apply
          </Button>
          <p style={{ color: "red" }}>
            {getCartData.promoError && getCartData.promoError}
          </p>
        </div>
      </div>
      <div className="cart-bottom">
        <div className="cart-total-cost">
          <label>TOTAL COST</label>
          <span>${getCartData.totalAmount}.00</span>
        </div>
        <div className="cart-button">
          <Link to={"/confirmation"}>
            <Button className="catr-btn">Continue</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Cart;
