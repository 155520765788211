import React, { useEffect, useState } from "react";
import { Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { setDestination } from "../redux/searchHotelSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";

const { Option } = Select;

const Destination = () => {
  const API_BASE_URL = "https://www.hotelapps.cloud/api";
  const [hotels, setHotels] = useState([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const destination = useSelector((state) => state.searchHotel.destination);

  const handleDestinationChange = (value) => {
    dispatch(setDestination(value));
  };

  const fetchHotels = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${API_BASE_URL}/getHotels.php`);
      const hotelData = response.data.data;
      const uniqueHotels = hotelData.filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.city === item.city)
      );

      setHotels(uniqueHotels);
    } catch (error) {
      console.error("Error fetching Hotels:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHotels();
  }, []);

  const destinationList = [
    {
      city : 'New York',
      country : 'United States'
    },
    {
      city : 'Chicago',
      country : 'United States'
    },
    {
      city : 'Dallas',
      country : 'United States'
    },
    {
      city : 'Toronto',
      country : 'Canada'
    },
    {
      city : 'Edinburgh',
      country : 'United Kingdom'
    },
    {
      city : 'Las Vegas',
      country : 'United States'
    },
    {
      city : 'London',
      country : 'United Kingdom'
    },
    {
      city : 'Nashville',
      country : 'United States'
    },
    {
      city : 'New Orleans',
      country : 'United States'
    },
    {
      city : 'Mumbai',
      country : 'India'
    },
  ]

  return (
    <div className="form-group">
      <label htmlFor="">
        <FontAwesomeIcon className="icon" icon={faLocationDot} /> Destination
      </label>
      <Select
        showSearch
        placeholder="Where Can We Take You"
        style={{ width: 200 }}
        value={destination || undefined}
        onChange={handleDestinationChange}
      >
        {destinationList.map((hotel, index) => (
          <Option
            loading={loading}
            key={index}
            value={`${hotel.city}, ${hotel.country}`}
          >
            {`${hotel.city}, ${hotel.country}`}
          </Option>
        ))}
      </Select>
    </div>
  );
};

export default Destination;
