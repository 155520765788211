import React, { useEffect, useState } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Checkbox,
  Radio,
  message,
  Form,
  InputNumber,
} from "antd";

const ConfirmForm = () => {
  const API_BASE_URL = "https://www.hotelapps.cloud/api";

  const date = "02-10-2024 : 04-10-2024";
  const selectedNight = 2;
  const selectedRoom = 2;
  const selectedAdult = 4;
  const selectedChildren = 0;

  const [formData, setFormData] = useState({
    fName: "",
    lName: "",
    email: "",
    mobileNumber: "",
    messageSendConfirmation: false,
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    zipCode: "",
    preferences: [],
    temperature: "Cool",
    VIPServices: "",
    extraFries: false,
    MacFriesorHouseMade: "Mac Fries",
    creditCardName: "",
    creditCardNumber: "",
    expirationMonth: "",
    expirationYear: "",
    CVVNumber: "",
    BillingZipCode: "",
    acceptTermCondition: false,
    selectedDate: "",
    night: 1,
    room: 1,
    adult: 1,
    children: 0,
  });

  const handleCustomFieldsChange = (fieldName, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: newValue,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    handleCustomFieldsChange("selectedDate", date);
    handleCustomFieldsChange("night", selectedNight);
    handleCustomFieldsChange("room", selectedRoom);
    handleCustomFieldsChange("adult", selectedAdult);
    handleCustomFieldsChange("children", selectedChildren);
  }, [date, selectedNight, selectedRoom, selectedAdult, selectedChildren]);

  const handleSubmit = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/submitBooking.php`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(formData),
      });
      const result = await response.json();
      if (response.ok) {
        message.success("Booking successful!");
      } else {
        message.error("Error in booking: " + result.message);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      message.error("An error occurred while submitting the form.");
    }
  };

  const handlePreferencesChange = (preference) => {
    setFormData((prevData) => {
      const newPreferences = prevData.preferences.includes(preference)
        ? prevData.preferences.filter((pref) => pref !== preference)
        : [...prevData.preferences, preference];
      return { ...prevData, preferences: newPreferences };
    });
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <div className="confirmation-form">
      {/* <Form layout="vertical">
        <Form.Item
          label="First Name"
          name="fName"
          rules={[
            { required: true, message: "Please enter your First Name" },
            { min: 3, message: "Name must be at least 3 characters" },
            {
              pattern: /^[a-zA-Z]+$/,
              message: "Name can only contain alphabets",
            },
          ]}
        >
          <Input placeholder="Enter your first name" />
        </Form.Item>

        <Form.Item
          label="Last Name"
          name="lName"
          rules={[
            { required: true, message: "Please enter your last name" },
            { min: 3, message: "Name must be at least 3 characters" },
            {
              pattern: /^[a-zA-Z]+$/,
              message: "Name can only contain alphabets",
            },
          ]}
        >
          <Input placeholder="Enter your last name" />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: "Please enter your email" },
            { type: "email", message: "Please enter a valid email address" },
          ]}
        >
          <Input placeholder="Enter your email" />
        </Form.Item>

        <Form.Item
          label="Mobile Number"
          name="mobileNumber"
          rules={[
            {
              pattern: /^[0-9]{10}$/,
              message: "Mobile Number must be exactly 10 digits",
            },
          ]}
        >
          <InputNumber placeholder="Enter your Mobile Number" />
        </Form.Item>

        <Form.Item >
          <Button className="btn-primary" type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>

      </Form> */}

      <div className="guest-information">
        <h3>Guest Information</h3>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">First Name</label>
              <Input
                name="fName"
                value={formData.fName}
                onChange={handleChange}
                placeholder="First Name"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Last Name</label>
              <Input
                name="lName"
                value={formData.lName}
                onChange={handleChange}
                placeholder="Last Name"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Email</label>
              <Input
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Member Number (Optional)</label>
              <Input
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                placeholder="Mobile Number"
              />
            </div>
          </Col>
          {/* <Col xs={24} sm={12} md={24} lg={24} xl={24}>
            <div className="form-group">
              <Checkbox
                name="messageSendConfirmation"
                checked={formData.messageSendConfirmation}
                onChange={handleChange}
              >
                Send my reservation confirmation directly via SMS
              </Checkbox>
            </div>
          </Col> */}
        </Row>
      </div>
      <div className="guest-address">
        <h3>Guest Address</h3>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Address Line 1</label>
              <Input
                name="address1"
                value={formData.address1}
                onChange={handleChange}
                placeholder="Address"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Address Line 2</label>
              <Input
                name="address2"
                value={formData.address2}
                onChange={handleChange}
                placeholder="Address"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">City</label>
              <Input
                name="city"
                value={formData.city}
                onChange={handleChange}
                placeholder="city"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">State/Provine</label>
              <Input
                name="state"
                value={formData.state}
                onChange={handleChange}
                placeholder="state"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Country/Region</label>
              <Input
                name="country"
                value={formData.country}
                onChange={handleChange}
                placeholder="country"
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Zip Code / Postal Code</label>
              <Input
                name="zipCode"
                value={formData.zipCode}
                onChange={handleChange}
                placeholder="zipCode"
              />
            </div>
          </Col>
        </Row>
      </div>
      <div className="room-preference">
        <h3>Room Request and Preferences</h3>
        <div className="room-preference-option">
          <h4>Select Option</h4>
          <div className="room-preference-option-list">
            <Checkbox
              onChange={() => handlePreferencesChange("Early Check in")}
            >
              Early Check-in
            </Checkbox>
            <Checkbox
              onChange={() => handlePreferencesChange("Late Check out")}
            >
              Late Check out
            </Checkbox>
            <Checkbox
              onChange={() => handlePreferencesChange("In-Room services")}
            >
              In-Room services
            </Checkbox>
            <Checkbox
              onChange={() =>
                handlePreferencesChange("Extra towel, pillow, blanket")
              }
            >
              Extra towel, pillow, blanket
            </Checkbox>
            <Checkbox
              onChange={() =>
                handlePreferencesChange("Baby Crib or Antilop High Chair")
              }
            >
              Baby Crib or Antilop High Chair
            </Checkbox>
            <Checkbox
              onChange={() => handlePreferencesChange("Laundry services")}
            >
              Laundry services
            </Checkbox>
            <Checkbox
              onChange={() => handlePreferencesChange("Valet services")}
            >
              Valet services
            </Checkbox>
            <Checkbox
              onChange={() => handlePreferencesChange("Room Temperature Cold")}
              disabled={formData.preferences.includes("Room Temperature Warm")}
            >
              Room Temperature Cold
            </Checkbox>

            <Checkbox
              onChange={() => handlePreferencesChange("Room Temperature Warm")}
              disabled={formData.preferences.includes("Room Temperature Cold")}
            >
              Room Temperature Warm
            </Checkbox>
          </div>
        </div>
      </div>
      <div className="room-temperature">
        {/* <h3>Prefer Room Temperature</h3>
        <div className="room-temperature-option">
          <div className="room-temperature-option-list">
            <Radio.Group
              name="temperature"
              value={formData.temperature}
              onChange={(e) =>
                setFormData({ ...formData, temperature: e.target.value })
              }
            >
              <Radio value="Cool">Cool</Radio>
              <Radio value="Warm">Warm</Radio>
            </Radio.Group>
          </div>
        </div> */}
        <Row gutter={24}>
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="form-group">
              <label className="form-label">Other VIP Services</label>
              <Input.TextArea
                name="VIPServices"
                value={formData.VIPServices}
                onChange={handleInputChange}
                rows={4}
              />
            </div>
          </Col> */}
          {/* <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <div className="form-group">
              <Checkbox
                name="extraFries"
                checked={formData.extraFries}
                onChange={handleInputChange}
              >
                Want Extra Fries with that?
              </Checkbox>
            </div>
          </Col> */}
        </Row>
        <div className="room-mac-fires">
          <h3>Add Mac Fries or House Made</h3>
          <div className="room-temperature-option">
            <div className="room-temperature-option-list">
              <Radio.Group
                name="MacFriesorHouseMade"
                value={formData.MacFriesorHouseMade}
                onChange={handleInputChange}
              >
                <Radio value="Mac Fries">Mac Fries</Radio>
                <Radio value="House Made">House Made</Radio>
              </Radio.Group>
            </div>
          </div>
        </div>
      </div>
      <div className="payment-information">
        <h3>Payment Information</h3>
        <Row gutter={24}>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Cardholder Name</label>
              <Input
                name="creditCardName"
                value={formData.creditCardName}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Credit/Debit Card Number</label>
              <Input
                name="creditCardNumber"
                value={formData.creditCardNumber}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Expiration Month</label>
              <Input
                name="expirationMonth"
                value={formData.expirationMonth}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Expiration Year</label>
              <Input
                name="expirationYear"
                value={formData.expirationYear}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">CVV Number</label>
              <Input
                name="CVVNumber"
                value={formData.CVVNumber}
                onChange={handleInputChange}
              />
            </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <div className="form-group">
              <label className="form-label">Billing Zip Code</label>
              <Input
                name="BillingZipCode"
                value={formData.BillingZipCode}
                onChange={handleInputChange}
              />
            </div>
          </Col>
        </Row>
      </div>
      <p>
        All guest rooms and amenities at this hotel offer complementary wireless
        internet access. We encourage you to check in via the mobile app or the
        email link sent prior to your arrival for a smooth check-in experience.
      </p>
      <br></br>
      {/* <p> Any maintenance related message.   – admin</p> */}
      <br></br>
      <br></br>
      <div className="form-group">
        <Checkbox
          name="acceptTermCondition"
          checked={formData.acceptTermCondition}
          onChange={handleInputChange}
        >
          I have read the all terms, rate details, and accept the cancellation
          policy.
        </Checkbox>
      </div>
      <Button onClick={handleSubmit} className="btn-book-stay">
        BOOK MY STAY
      </Button>
    </div>
  );
};

export default ConfirmForm;
