import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addItemToCart, addRoomInCart, clearCart } from "../redux/cartSlice";

const ShowUpgrades = ({ hotelId }) => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };

  const upgradsData = [
    {
      id: "upgrad1",
      hotelId: "hotel1",
      img: "images/room-img-10.jpg",
      roomName: "High Floor with Standard Room",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 345,
    },
    {
      id: "upgrad2",
      hotelId: "hotel1",
      img: "images/room-img-11.jpg",
      roomName: "Low Floor with Standard Room",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad3",
      hotelId: "hotel1",
      img: "images/room-img-12.jpg",
      roomName: "Near Elevator",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad4",
      hotelId: "hotel1",
      img: "images/room-img-13.jpg",
      roomName: "Room with View",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad5",
      hotelId: "hotel1",
      img: "images/room-img-14.jpg",
      roomName: "Corner Room",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad6",
      hotelId: "hotel2",
      img: "images/room-img-15.jpg",
      roomName: "Pent House",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad7",
      hotelId: "hotel2",
      img: "images/room-img-16.jpg",
      roomName: "3 Bed Unit",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad8",
      hotelId: "hotel2",
      img: "images/room-img-17.jpg",
      roomName: "Adjacent Room",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad9",
      hotelId: "hotel2",
      img: "images/room-img-18.jpg",
      roomName: "Room with Balcony",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad10",
      hotelId: "hotel3",
      img: "images/room-img-19.jpg",
      roomName: "Room with Patio",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad11",
      hotelId: "hotel3",
      img: "images/room-img-20.jpg",
      roomName: "Accessible Room",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad11",
      hotelId: "hotel3",
      img: "images/room-img-21.jpg",
      roomName: "Room with Package",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
    {
      id: "upgrad11",
      hotelId: "hotel3",
      img: "images/room-img-22.jpg",
      roomName: "Room with Offers",
      description: "235 SF room with an unobstructed view, queen bed, and large TV.",
      price: 200,
    },
  ];

  const filteredRooms = upgradsData.filter((room) => room.hotelId === hotelId);

  // Add Item In Cart
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { destination, date, roomAndGuest } = useSelector(
    (state) => state.searchHotel
  );

  const addToCart = (item) => {
    dispatch(
      addRoomInCart({
        id: item.id,
        hotelId: item.hotelId,
        type : 'upgrade',
        name: item.roomName,
        checkIn: date.checkIn,
        checkOut: date.checkOut,
        guests: roomAndGuest.adults,
        price: item.price,
        nights: date.nights,
        totalPrice: item.price * date.nights,
      })
    );
    dispatch(clearCart());
    navigate("/personalization");
  };

  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={3000}
      keyBoardControl={true}
      itemClass="show-upgrades"
    >
      {filteredRooms.map((item, index) => (
        <div key={index} className="room-item" style={{ padding: "0 15px" }}>
          <div className="room-image">
            <img
              src={item.img}
              alt="Hotel"
              className="room-img"
            />
          </div>
          <div className="room-details">
            <h3 className="room-quality">{item.roomName}</h3>
            <p className="room-description">{item.description}</p>
            <span className="room-rate">
              Standard Rate ${item.price}.00 / night
            </span>
            <div className="room-rate-footer">
              <span className="room-price">${item.price}</span>
              <button onClick={() => addToCart(item)} className="btn-select">Select</button>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default ShowUpgrades;
