import React from "react";
import ConfirmForm from "../Components/ConfirmForm";
import Step from "../Components/Step";

const Confirmation = () => {
  return (
    <div style={{paddingBottom:'50px'}}>
      <div className="container">
      <Step active={4} />
      <ConfirmForm />

      </div>
    </div>
  );
};

export default Confirmation;
