import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedRoom : {},
    items: [],
    taxes: 10,
    discount: 5,
    subTotal: 0,
    totalAmount: 0,
    appliedPromoCode: null,
    promoError: "",
    promoAmount: 0,
};

const cartSlice = createSlice({
    name: "cart",
    initialState,
    reducers: {
        addRoomInCart : (state, action) => {
            state.selectedRoom = action.payload;

            state.subTotal = state.selectedRoom?.totalPrice + state.items.reduce((total, item) => total + item.totalPrice, 0);
            const taxAmount = state.taxes;
            const discountAmount = state.discount;
            state.totalAmount = state.subTotal + taxAmount - discountAmount;
        },
        addItemToCart: (state, action) => {
            const newItem = action.payload;

            state.items.push({
                id: newItem.id,
                hotelId: newItem.hotelId,
                name: newItem.name,
                checkIn: newItem.checkIn || null,
                checkOut: newItem.checkOut || null,
                guests: newItem.guests || null,
                price: newItem.price || 0,
                nights: newItem.nights || null,
                totalPrice: newItem.totalPrice,
                quantity: newItem.quantity || 1,
                date: newItem.date || null,
                time: newItem.time || null,
                occasion: newItem.occasion || null,
                specials: newItem.specials || null,
                Experience: newItem.Experience || null,
            });

            state.subTotal = state.selectedRoom?.totalPrice + state.items.reduce((total, item) => total + item.totalPrice, 0);
            const taxAmount = state.taxes;
            const discountAmount = state.discount;
            state.totalAmount = state.subTotal + taxAmount - discountAmount;
        },
        removeItemFromCart: (state, action) => {
            const id = action.payload;
            const index = state.items.findIndex((item) => item.id === id);
            if (index !== -1) {
                state.items.splice(index, 1);
            }
            state.subTotal = state.selectedRoom?.totalPrice + state.items.reduce((total, item) => total + item.totalPrice, 0);
            const taxAmount = state.taxes;
            const discountAmount = state.discount;
            state.totalAmount = state.subTotal + taxAmount - discountAmount;
        },
        clearCart: (state) => {
            state.items = [];
            state.subTotal = state.selectedRoom?.totalPrice + state.items.reduce((total, item) => total + item.totalPrice, 0);
            const taxAmount = state.taxes;
            const discountAmount = state.discount;
            state.totalAmount = state.subTotal + taxAmount - discountAmount;
        },
        applyPromoCode: (state, action) => {
            const promoCode = action.payload;
            const promoCodes = {
                PROMO01: { type: "percentage", code: "PROMO01", value: 10 },
                PROMO02: { type: "percentage", code: "PROMO02", value: 5 },
                PROMO03: { type: "fixed", code: "PROMO03", value: 5 },
            };

            if (state.appliedPromoCode) {
                if (state.appliedPromoCode.code === promoCode) {
                    state.promoError = "Promocode already applied.";
                } else {
                    state.promoError = "Only 1 promocode applied.";
                }
                return;
            }

            if (promoCodes[promoCode]) {
                const promo = promoCodes[promoCode];
                state.appliedPromoCode = promo;
                state.promoError = "";

                if (promo.type === "percentage") {
                    state.promoAmount = (state.subTotal * promo.value) / 100;
                } else if (promo.type === "fixed") {
                    state.promoAmount = promo.value;
                }

                const taxAmount = state.taxes;
                const discountAmount = state.discount;
                state.totalAmount = state.subTotal + taxAmount - discountAmount - state.promoAmount;
            } else {
                state.promoError = "Invalid promocode.";
            }
        },
        clearPromoError: (state) => {
            state.promoError = "";
        },
        clearPromoCode: (state) => {
            state.appliedPromoCode = null;
            state.promoAmount = 0;
            state.promoError = "";
        
            const taxAmount = state.taxes;
            const discountAmount = state.discount;
            state.totalAmount = state.subTotal + taxAmount - discountAmount;
        },
    },
});

export const { addItemToCart, removeItemFromCart, clearCart, applyPromoCode, clearPromoError, clearPromoCode, addRoomInCart } =
    cartSlice.actions;

export default cartSlice.reducer;
