import React, { useState } from "react";
import { Button, Dropdown, InputNumber, Row, Col } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { setRoomAndGuest } from "../redux/searchHotelSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserTag } from "@fortawesome/free-solid-svg-icons";

const RoomAndGuest = () => {
  const dispatch = useDispatch();
  const roomAndGuest = useSelector((state) => state.searchHotel.roomAndGuest);
  const [visible, setVisible] = useState(false);

  const handleRoomChange = (rooms) =>
    dispatch(setRoomAndGuest({ ...roomAndGuest, rooms }));
  const handleAdultChange = (adults) =>
    dispatch(setRoomAndGuest({ ...roomAndGuest, adults }));
  const handleChildrenChange = (children) =>
    dispatch(setRoomAndGuest({ ...roomAndGuest, children }));

  const menu = (
    <div>
      <Row>
        <Col span={8}>Rooms</Col>
        <Col span={16}>
          <InputNumber
            min={1}
            value={roomAndGuest.rooms}
            onChange={handleRoomChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>Adults</Col>
        <Col span={16}>
          <InputNumber
            min={1}
            value={roomAndGuest.adults}
            onChange={handleAdultChange}
          />
        </Col>
      </Row>
      <Row>
        <Col span={8}>Children</Col>
        <Col span={16}>
          <InputNumber
            min={0}
            value={roomAndGuest.children}
            onChange={handleChildrenChange}
          />
        </Col>
      </Row>
    </div>
  );

  return (
    <div className="form-group">
      <label htmlFor="">
        <FontAwesomeIcon className="icon" icon={faUserTag} />
        Room & Guest
      </label>
      <Dropdown overlay={menu} visible={visible} onVisibleChange={setVisible}>
        <Button>
          {roomAndGuest.rooms} Room, {roomAndGuest.adults} Adult
          {roomAndGuest.adults > 1 ? "s" : ""} <DownOutlined />
        </Button>
      </Dropdown>
    </div>
  );
};

export default RoomAndGuest;
