import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useNavigate } from "react-router-dom";
import RoomDetails from "./RoomDetails";
import { Modal } from "antd";
import { addItemToCart, addRoomInCart, clearCart } from "../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";

const ShowRoom = ({ hotelId }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRoom, setselectedRoom] = useState(null);

  const showModal = (hotel) => {
    setselectedRoom(hotel);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 767, min: 576 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 575, min: 0 },
      items: 1,
    },
  };

  const roomData = [
    {
      id: "room1",
      hotelId: "hotel1",
      img: "images/room-img-01.jpg",
      roomName: "Standard Room with King Bed",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
    {
      id: "room2",
      hotelId: "hotel1",
      img: "images/room-img-02.jpg",
      roomName: "Standard Room with Queen Bed",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
    {
      id: "room3",
      hotelId: "hotel1",
      img: "images/room-img-03.jpg",
      roomName: "Double Room",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
    {
      id: "room4",
      hotelId: "hotel1",
      img: "images/room-img-04.jpg",
      roomName: "Room with Packages",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
    {
      id: "room5",
      hotelId: "hotel1",
      img: "images/room-img-05.jpg",
      roomName: "2 Bed Room Suites",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
    {
      id: "room6",
      hotelId: "hotel2",
      img: "images/room-img-06.jpg",
      roomName: "3 Bed Room Suites",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
    {
      id: "room7",
      hotelId: "hotel2",
      img: "images/room-img-07.jpg",
      roomName: "Premium Room with Pool",
      description:
        'Enjoy two king beds and relax in comfort. The 50" TV and Blue-ray entertainment system is amazing.',
      price: 100,
    },
  ];

  const filteredRooms = roomData.filter((room) => room.hotelId === hotelId);

  // Add Item In Cart
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { destination, date, roomAndGuest } = useSelector(
    (state) => state.searchHotel
  );

  const addToCart = (item) => {
    dispatch(
      addRoomInCart({
        id: item.id,
        hotelId: item.hotelId,
        type : 'room',
        name: item.roomName,
        checkIn: date.checkIn,
        checkOut: date.checkOut,
        guests: roomAndGuest.adults,
        price: item.price,
        nights: date.nights,
        totalPrice: item.price * date.nights, 
      })
    );
    dispatch(clearCart());
    navigate("/personalization");
  };

  return (
    <>
      <Carousel
        responsive={responsive}
        swipeable={true}
        draggable={true}
        showDots={false}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        keyBoardControl={true}
        itemClass="show-room"
      >
        {filteredRooms.map((item, index) => (
          <div key={index} className="room-item" style={{ padding: "0 15px" }}>
            <div className="room-image">
              <img
                src={item.img}
                alt="Hotel"
                className="room-img"
              />
            </div>
            <div className="room-details">
              <h3 style={{cursor:'pointer'}} className="room-quality" onClick={() => showModal(item)}>
                {item.roomName}
              </h3>
              <p className="room-description">{item.description}</p>
              <span className="room-rate">
                Standard Rate ${item.price}.00 / night
              </span>
              <div className="room-rate-footer">
                <span className="room-price">${item.price}</span>
                {/* <Link to={"/personalization"}> */}
                <button onClick={() => addToCart(item)} className="btn-select">
                  Select
                </button>
                {/* </Link> */}
              </div>
            </div>
          </div>
        ))}
      </Carousel>

      <Modal
        title={selectedRoom ? selectedRoom.roomname : "Room Details"}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
      >
        {selectedRoom && (
          <>
            <RoomDetails selectedRoom={selectedRoom} />
          </>
        )}
      </Modal>
    </>
  );
};

export default ShowRoom;
