import React, { useState } from "react";
import { DatePicker } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setDate } from "../redux/searchHotelSlice";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";

const { RangePicker } = DatePicker;

const SelectDate = () => {
  const dispatch = useDispatch();
  const selectedDate = useSelector((state) => state.searchHotel.date);

  //   select Date
  const handleDateChange = (dates) => {
    const formattedDates = dates
      ? {
          checkIn: dates[0].format("DD-MM-YYYY"),
          checkOut: dates[1].format("DD-MM-YYYY"),
          nights: dates[1].diff(dates[0], "days"),
        }
      : { checkIn: null, checkOut: null, nights: 0 };
    dispatch(setDate(formattedDates));
  };

  //   disabled Date
  const unavailableDates = ["2024-11-20", "2024-11-21"];

  const isDisabled = (current) => {
    const currentDate = current.format("YYYY-MM-DD");

    return (
      current &&
      (current < moment().startOf("day") ||
        unavailableDates.includes(currentDate))
    );
  };

  // Date Custom Price
  const datePrices = {
    "2024-11-22": 150,
    "2024-11-23": 180,
    "2024-11-24": 200,
    "2024-11-25": 220,
    "2024-11-26": 250,
  };

  const dateRender = (current) => {
    const currentDate = current.format("YYYY-MM-DD");
    const price = datePrices[currentDate] || 100;

    if (isDisabled(current)) {
      return (
        <div className="ant-picker-cell-inner">
          <div>{current.date()}</div>
        </div>
      );
    }

    return (
      <div className="ant-picker-cell-inner">
        <div>{current.date()}</div>
        <div style={{ fontSize: "10px", color: "#888" }}>₹{price}</div>
      </div>
    );
  };

  return (
    <div className="form-group">
      <label htmlFor="">
        <FontAwesomeIcon className="icon" icon={faCalendarDays} /> {selectedDate.nights}{" "}
        Night
      </label>
      <RangePicker
        disabledDate={isDisabled}
        // dateRender={dateRender}
        onChange={handleDateChange}
        value={
          selectedDate.checkIn && selectedDate.checkOut
            ? [
                moment(selectedDate.checkIn, "DD-MM-YYYY"),
                moment(selectedDate.checkOut, "DD-MM-YYYY"),
              ]
            : null
        }
      />
    </div>
  );
};

export default SelectDate;
