import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Dropdown,
  Form,
  Menu,
  Select,
  Space,
  TimePicker,
} from "antd";
import React, { useState } from "react";
import { addItemToCart } from "../../redux/cartSlice";
import { useDispatch, useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import moment from "moment";

const { Option } = Select;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 3,
  },
  desktop: {
    breakpoint: { max: 1199, min: 992 },
    items: 2,
  },
  tablet: {
    breakpoint: { max: 991, min: 768 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

const Dining = () => {
  const [itemStates, setItemStates] = useState({});
  const dispatch = useDispatch();

  const selectedDate = useSelector((state) => state.searchHotel.date);


  const data = [
    {
      id: "01",
      hotelId: "hotel1",
      title: "Restaurant 1",
      image: "./tab/dining-1.jpg",
      content: "Asian fusion restaurant at HRH Nevada, Las Vegas that uses fresh ingredients and poolside seating. ",
      hours: true,
      Occasion: [
        {
          id: 1,
          value: "Birthday",
        },
        {
          id: 2,
          value: "Anniversary ",
        },
        {
          id: 3,
          value: "Family reunion  ",
        },
        {
          id: 4,
          value: "Graduation ",
        },
      ],
      Specials: [
        {
          id: 1,
          value: "Beer Pairing with Burger  ",
          price: 50,
        },
        {
          id: 2,
          value: "Veggie platter  ",
          price: 70,
        },
        {
          id: 3,
          value: "Sweet Tooth Delight",
          price: 20,
        },
      ],
    },
    {
      id: "02",
      hotelId: "hotel1",
      title: "Restaurant 2",
      image: "./tab/dining-2.jpg",
      content: "Asian fusion restaurant at HRH Nevada, Las Vegas that uses fresh ingredients and poolside seating. ",
      hours: true,
      Occasion: [
        {
          id: 1,
          value: "Birthday",
        },
        {
          id: 2,
          value: "Anniversary ",
        },
        {
          id: 3,
          value: "Family reunion  ",
        },
        {
          id: 4,
          value: "Graduation ",
        },
      ],
      Specials: [
        {
          id: 1,
          value: "Beer Pairing with Burger  ",
          price: 50,
        },
        {
          id: 2,
          value: "Veggie platter  ",
          price: 70,
        },
        {
          id: 3,
          value: "Sweet Tooth Delight",
          price: 20,
        },
      ],
    },
    {
      id: "03",
      hotelId: "hotel1",
      title: "Restaurant 3",
      image: "./tab/dining-3.jpg",
      content: "Asian fusion restaurant at HRH Nevada, Las Vegas that uses fresh ingredients and poolside seating. ",
      hours: true,
      Occasion: [
        {
          id: 1,
          value: "Birthday",
        },
        {
          id: 2,
          value: "Anniversary ",
        },
        {
          id: 3,
          value: "Family reunion  ",
        },
        {
          id: 4,
          value: "Graduation ",
        },
      ],
      Specials: [
        {
          id: 1,
          value: "Beer Pairing with Burger  ",
          price: 50,
        },
        {
          id: 2,
          value: "Veggie platter  ",
          price: 70,
        },
        {
          id: 3,
          value: "Sweet Tooth Delight",
          price: 20,
        },
      ],
    },
  ];

  const addToCart = (item) => {
    const { date, time, occasion, specials } = itemStates[item.id] || {};
    if (date && time) {
      dispatch(
        addItemToCart({
          id: item.id,
          hotelId: item.hotelId,
          name: item.title,
          price: specials?.price || 0,
          totalPrice: specials?.price || 0,
          date,
          time,
          occasion: occasion?.value || null,
          specials: specials?.value || null,
        })
      );
    } else {
      alert("Please select all required options.");
    }
  };


  const handleStateChange = (id, field, value) => {
    setItemStates((prevState) => ({
      ...prevState,
      [id]: {
        ...(prevState[id] || {}),
        [field]: value,
      },
    }));
  };


  return (
    <Carousel
      responsive={responsive}
      swipeable={true}
      draggable={true}
      showDots={false}
      infinite={true}
      autoPlay={false}
      customTransition="transform 0.7s ease-in-out"
      autoPlaySpeed={3000}
      keyBoardControl={true}
      transitionDuration={500}
      containerClass="carousel-container"
      // removeArrowOnDeviceType={["tablet", "mobile"]}
      itemClass="dining"
    >
      {data.map((item) => (
        <div key={item.id} className="room-item" style={{ padding: "0 7px" }}>
          <div className="room-image">
            <img
              src={item.image}
              alt="Hotel"
              className="room-img"
            />
          </div>
          <div className="room-details">
            <h3 className="room-quality">{item.title}</h3>
            <p className="room-description">{item.content}</p>
            <div className="select-date-time-option">
              <div className="select-date">
                <label>Select Date</label>
                <DatePicker
                disabledDate={(current) => {
                  const checkIn = moment(selectedDate.checkIn, "DD-MM-YYYY");
                  const checkOut = moment(selectedDate.checkOut, "DD-MM-YYYY");
                  return current && (current < checkIn || current > checkOut);
                }}
                onChange={(date, dateString) =>
                  handleStateChange(item.id, "date", dateString)
                }
              />
              </div>
              <div className="select-time">
                <label>Select Time</label>
                <Form.Item name="time-picker">
                <TimePicker
                onChange={(time, timeString) =>
                  handleStateChange(item.id, "time", timeString)
                }
              />
                </Form.Item>
              </div>
            </div>

            <Select
              placeholder="Select Occasion"
              style={{ width: "100%" }}
              onChange={(value) =>
                handleStateChange(
                  item.id,
                  "occasion",
                  item.Occasion.find((opt) => opt.value === value)
                )
              }
              value={itemStates[item.id]?.occasion?.value || undefined}
            >
              {item.Occasion.map((option) => (
                <Option key={option.id} value={option.value}>
                  {option.value}
                </Option>
              ))}
            </Select>

            <Select
              placeholder="Select Special"
              style={{ width: "100%" }}
              onChange={(value) =>
                handleStateChange(
                  item.id,
                  "specials",
                  item.Specials.find((opt) => opt.value === value)
                )
              }
              value={itemStates[item.id]?.specials?.value || undefined}
            >
              {item.Specials.map((option) => (
                <Option key={option.id} value={option.value}>
                  {option.value} - ${option.price}
                </Option>
              ))}
            </Select>

            <div className="room-rate-footer">
              <button onClick={() => addToCart(item)} className="btn-add-stay">
                <span className="hide-text">Add to My Stay</span>{" "}
                <PlusOutlined />
              </button>
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  );
};

export default Dining;
