import React from "react";
import Step from "../Components/Step";
import SearchForm from "../Components/SearchForm";
import HotelList from "../Components/HotelList";
import ShowUpgrades from "../Components/ShowUpgrades";
import Filter from "../Components/Filter";
import HotelDetails from "../Components/HotelDetails";

const SelectDate = () => {
  return (
    <div className="container">
      <div className='row'>
        <div className="col-12">
          <Step active={1} />
          <SearchForm />
         </div>
      </div>
    </div>
  );
};

export default SelectDate;
