import React from "react";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Button, Col, Rate, Row } from "antd";
import { EnvironmentOutlined, CheckOutlined, DownOutlined} from "@ant-design/icons";

const HotelDetails = () => {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  return (
    <div className="hotel-details">
        <div className="images-gallery">
              <Carousel 
                responsive={responsive}
                swipeable={true}
                draggable={true}
                showDots={false}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={3000}
                keyBoardControl={true}
                itemClass='show-offer'
              >
                <div>
                  <img
                      src="images/hotel-img-01.jpg"
                      alt="Hotel"
                      width="100%"
                    />
                </div>
                <div>
                  <img
                      src="images/hotel-img-02.jpg"
                      alt="Hotel"
                      width="100%"
                    />
                </div>
              </Carousel>
        </div>
        <div className="hotel-details-info">
          <div className="location"><EnvironmentOutlined /> 1127 North Orlando Avenue Winter Park, Florida, USA, 32789</div>
          <div className="rating">
            <Rate allowHalf defaultValue={4.5} />{" "}
            <span className="review-text">(273 reviews)</span>
          </div>
        </div>
        <Button className="btn-primary" type="primary">Select this Hotel</Button>
        <div className="hotel-overview">
          <h3>Overview</h3>
          <p>SpringHill Suites by Marriott® Winter Park, offers spacious suites and stylish lobbies with flexible spaces perfect for working, 
          meeting or relaxing. Modern amenities help you stay refreshed and focused when traveling to Central Florida.</p>
        </div>
        <div className="hotel-contact-info">
          <Row>
            <Col xs={24} sm={24} md={10} lg={9} xl={9}>
              <h4>Location</h4>
              <div className="address">
                <span>SpringHill Suites Winter Park  1127 North 
                Orlando Avenue Winter Park, Florida,
                 USA, 32789</span>
                <span>Telephone: +1 407-960-4624</span>
                <span>Email : info@hotels.com</span>
              </div>
            </Col>
            <Col xs={24} sm={24} md={14} lg={15} xl={15}>
              <div className="map"></div>
            </Col>
          </Row>
        </div>
        <div className="room-information">
          <h3 className="room-detail-header">Hotel Information</h3>
          <div className="roomContainer">
            <div className="room-info"><CheckOutlined /> <span className="room-info-name">Balcony</span></div>
            <div className="room-info"><CheckOutlined /> <span className="room-info-name">CHECK OUT: 12:00 PM</span></div>
            <div className="room-info"><CheckOutlined /> <span className="room-info-name">This hotel has a smoke-free policy</span></div>
            <div className="room-info"><CheckOutlined /> <span className="room-info-name">See Accessibility Features</span></div>
            <div className="room-info"><CheckOutlined /> <span className="room-info-name">Pet Policy : Not Allowed</span></div>
            <div className="room-info"><CheckOutlined /> <span className="room-info-name">Parking : For a Free</span></div>
          </div>
        </div>
        <div className="room-amenities">
          <h3 className="room-detail-header">Amenities</h3>
          <div className="amenitiesContainer">
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Free high-speed internet </span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Fitness center</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Free breakfast</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Parking</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Meeting event space</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Mobility accessible rooms</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Cocktail lounge with light fare</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Coffee/tea in-room</span></div>
            <div className="room-amenity"><CheckOutlined /> <span className="room-amenity-name">Coffee in lobby</span></div>
          </div>
          <div className="more-amenities">See all hotel amenities <DownOutlined /></div>
        </div>
    </div>
  );
};

export default HotelDetails;
