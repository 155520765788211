import React, { useState } from "react";
import { Button, Select, Drawer, Checkbox,  } from "antd";
import { DownOutlined, FilterOutlined } from "@ant-design/icons";

const { Option } = Select;

const Filter = () => {
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  const [selectedHotelType, setSelectedHotelType] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [visible, setVisible] = useState(false); // State for drawer visibility

  const handleSelectChange = (value, setter) => {
    setter(value);
  };

  // Functions to handle drawer visibility
  const showDrawer = () => {
    setVisible(true);
  };

  const closeDrawer = () => {
    setVisible(false);
  };

  return (
    <div className="filter-item">
      <ul>
        {/* <li>
          <div
            mode="multiple"
            placeholder="Select Amenities"
            value={selectedAmenities}
            onChange={(value) => handleSelectChange(value, setSelectedAmenities)}
            suffixIcon={<DownOutlined />}
            style={{ width: 200 }}
          >
            <Option value="wifi">Free WiFi</Option>
            <Option value="pool">Swimming Pool</Option>
            <Option value="parking">Parking</Option>
          </div>
        </li> */}

        <li>
          <Select
            placeholder="Select Hotel Type"
            value={selectedHotelType}
            onChange={(value) => handleSelectChange(value, setSelectedHotelType)}
            suffixIcon={<DownOutlined />}
            allowClear
          >
            <Option value="resort">Resort</Option>
            <Option value="boutique">Boutique</Option>
            <Option value="budget">Budget</Option>
          </Select>
        </li>

        <li>
          <Select
            placeholder="Select Country"
            value={selectedCountry}
            onChange={(value) => handleSelectChange(value, setSelectedCountry)}
            suffixIcon={<DownOutlined />}
            allowClear
          >
            <Option value="usa">USA</Option>
            <Option value="france">France</Option>
            <Option value="japan">Japan</Option>
          </Select>
        </li>

        <li>
          <Select
            placeholder="Select Price Range"
            value={selectedPrice}
            onChange={(value) => handleSelectChange(value, setSelectedPrice)}
            suffixIcon={<DownOutlined />}
            allowClear
          >
            <Option value="under100">Under $100</Option>
            <Option value="100-300">$100 - $300</Option>
            <Option value="above300">Above $300</Option>
          </Select>
        </li>
        
        <li>
          {/* Button to trigger the Drawer */}
          <Button onClick={showDrawer}>
            All Filter <FilterOutlined style={{ marginLeft: 8 }} />
          </Button>
        </li>
      </ul>

      {/* Drawer component to show filters */}
      <Drawer
        title="Filter Options"
        placement="right" // Sidebar opens from the right
        onClose={closeDrawer}
        visible={visible}  // Controls the visibility of the Drawer
        width={400}
      >
        <div>
          {/* Same filter options inside the drawer */}
          <div className="fillter-item-list">
            <h4>Amenities</h4>
            <Checkbox>Suites</Checkbox>
            <Checkbox>Fitness Center</Checkbox>
            <Checkbox>Pool</Checkbox>
            <Checkbox>Spa</Checkbox>
            <Checkbox>Restaurant on site</Checkbox>
            <Checkbox>Free breakfast </Checkbox>
            <Checkbox>Wellness</Checkbox>
            <Checkbox>Meeting Space</Checkbox>
            <Checkbox>Open Terrace </Checkbox>
          </div>
          <div className="fillter-item-list">
            <h4>Hotel Type</h4>
            <Checkbox>City hotel</Checkbox>
            <Checkbox>Airport Hotel</Checkbox>
            <Checkbox>Resort </Checkbox>
            <Checkbox>Extended Stay</Checkbox>
            <Checkbox>Pet Welcome</Checkbox>
            <Checkbox>Wellness </Checkbox>
          </div>
          <div className="fillter-item-list">
            <h4>Country/City</h4>
            <Checkbox>USA</Checkbox>
            <Checkbox>Canada Hotel</Checkbox>
            <Checkbox>India</Checkbox>
            <Checkbox>UK</Checkbox>
            <Checkbox>Caribbean</Checkbox>
          </div>
          <div className="fillter-item-list">
            <h4>Price </h4>
            <Checkbox>Any Price</Checkbox>
            <Checkbox>0 - 100 USD </Checkbox>
            <Checkbox>100 - 200 USD  </Checkbox>
            <Checkbox>200+ USD </Checkbox>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default Filter;
